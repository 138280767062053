<template>
  <div>
    <section class="section1 ">
      <div class="seccionlore1 backrosa container-fluid">
        <div class="row">
          <div class="col-9 pe-0">
            <p style="letter-spacing: -0.010em;" class=" titulo1 textorosa">
              El diseñador gráfico piensa con el lápiz
            </p>
            <p class="texto2 textorosa pe-2">
              Siempre se los decía a mis alumnos de nuevo ingreso el primer
              día de clases, y les regalaba uno. <br> <br>
              Se empieza a diseñar cuando un cliente te dice lo que necesita
              y tú ya lo estás visualizando. Después, simplemente lo plasmas
              en un boceto con un lápiz. Primer paso en un proyecto exitoso.
            </p>
          </div>
          <div class="col-3 ps-0">
            <img src="../assets/imagenes/inicio/lapiz.png" alt="Lapiz" class="lapizlore ">
          </div>
        </div>
      </div>
    </section>
    <!----------------------CONTENIDO----------------------->
    <section class="section2 mx-5 mb-5">
      <div class="elementolore1 container-fluid">
        <img src="../assets/imagenes/lore/senalizacion_de_lugar.png" alt="señalizador" class="señalizador">
        <p class="texto3 textogris ">
          Soy una diseñadora gráfica orgullosamente mexicana,
          una divertida mezcla de ciudad de México, Torreón,
          Acapulco, Puebla y Monterrey con un pequeño toque madrileño.
        </p>

      </div>

      <!----------------------CONTENIDO----------------------->
      <div class="elementolore1 container-fluid">
        <img src="../assets/imagenes/lore/edificios.png" alt="edificios" class="edificios">
        <p class="texto3 textogris">
          Una de las mejores vivencias que he disfrutado muchísimo,
          y que volvería a hacer, es el haber podido compartir mis
          conocimientos y experiencia profesional con mis queridos
          alumnos por más de una década en tres maravillosas universidades de México.
        </p>

      </div>
      <!----------------------CONTENIDO----------------------->
      <div class="elementolore1 container-fluid">
        <img src="../assets/imagenes/lore/lapices.png" alt="lapices" class="lapices">
        <p class="texto3 textogris">
          He tenido la oportunidad de enriquecer
          mi experiencia profesional participando
          en diversos proyectos de los cuales me
          gusta destacar mi aventura como freelance
          en Conceptos Gráficos, mi agencia de diseño gráfico en Madrid.
          Mi paso como Coordinadora de la licenciatura en Diseño Gráfico
          en la Universidad Loyola del Pacífico, en Acapulco y mi primer proyecto
          como responsable del departamento de Diseño y Publicidad en la asociación
          Educación por el Arte de Monterrey, A.C. ambos en México.

        </p>
      </div>
      <!----------------------CONTENIDO----------------------->
      <div class="elementolore1 container-fluid">
        <img src="../assets/imagenes/lore/birrete.png" alt="birrete" class="birrete">
        <p class="texto3 textogris">
          Mi formación académica superior
          tuvo lugar en Monterrey, un sitio
          que recuerdo con mucho cariño y
          en donde aún tengo buenos amigos.
          Concluí la Maestría en Artes con especialidad en Educación en el Arte y la
          licenciatura en Artes Visuales con especialidad en Diseño Gráfico en mi alma
          mater, la Universidad Autónoma de Nuevo León.
        </p>
      </div>
      <!----------------------CONTENIDO----------------------->
      <div class="elementolore1 container-fluid">
        <img src="../assets/imagenes/lore/corazon_1.png" alt="corazon" class="
            corazon1">
        <img src="../assets/imagenes/lore/corazon_2.png" alt="corazon" class="corazon2">
        <p style="text-align: justify;" class="texto3 textogris">
          Me considero una persona plena y satisfecha
          con todo lo que he logrado hasta hoy, disfruto
          mucho viajar y aprender; amo enseñar, diseñar y
          compartir lo mejor de la vida con seres maravillosos
          que voy conociendo en el camino.

        </p>
      </div>

    </section>
  </div>
</template>

<script>
export default {
  name: "Lore",
  mounted() {
    this.$store.commit("SET_FOOTER", [true,'backrosa']);
  }
}
</script>

<style scoped>

</style>